<template>
   <modal
    :adaptive="true"
    class="mobileDetailModal CEModal modal-scroll-bar"
    name="MobileDetailModal"
  >
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>

    <h3 class="mb-10">Itinerary details</h3>
    <FligntTicket></FligntTicket>
    <FligntTicket class="mt-7"></FligntTicket>
    <BaggageInfo class="mt-7" v-if="!isOrder"></BaggageInfo>
    <div class="d-flex justify-content-between mt-7" v-if="!isOrder">
      <router-link
        :to="{
          name: 'MobileFlightOrderReseller',
        }"
        slot="routerBlock" 
      >
        <button class="primary-btn primary-btn_sm bg-green6">
          {{ $t( "Book Flight" ) }}
        </button>
      </router-link>
      <button class="primary-btn primary-btn_sm bg-grey8" @click="closeModal()">
        Close
      </button>
    </div>
    <div class="df-c mt-7" v-if="isOrder">
      <button class="primary-btn primary-btn_sm bg-grey8" @click="closeModal()">
        Close
      </button>
    </div>
  </modal>
</template>

<script>
import  FligntTicket from "../tradeShow/FligntTicket.vue";
import  BaggageInfo from "../tradeShow/BaggageInfo.vue";
import  CovideNotify from "../tradeShow/CovideNotify.vue";
import  TripSummary from "../tradeShow/TripSummary.vue";

export default {
  name: 'MobileDetailModal',
  props: {
    isOrder: {
      type: Boolean,
      default: true
    }
  },
  components: {
    FligntTicket,
    BaggageInfo,
    CovideNotify,
    TripSummary
  }, 
  methods: {
    closeModal() {
      this.$modal.hide("MobileDetailModal");
    },
  }
}
</script>

<style lang="scss">
  .mobileDetailModal .vm--modal{
    padding: 20px;
  }
</style>

