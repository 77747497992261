<template>
  <section class="mobile-filter-modal" v-show="isFilterModalVisible">
      <p class="fs20 color-white text-center mb-5">{{ $t("Search your desire") }}</p>
      <div class="filter__item">
        <div class="d-flex align-items-center mb-4">
          <img src="/assets/img/plane.svg" alt="">
          <label class="label color-white mx-5">{{ $t("From") }}</label>
        </div>
        <input class="input filter__input" type="text" v-model="filters.from" />
      </div>

      <div class="filter__item">
        <div class="d-flex align-items-center mb-4">
          <img src="/assets/img/plane-arrival.svg" alt="">
          <label class="label color-white mx-5">{{ $t("To") }}</label>
        </div>
        <vue-select
          :options="arrival.options"
          class="input cus-select border-option-dropdown"
          v-model="arrival.selected"
        ></vue-select>
      </div>

      <div class="filter__item">
        <div class="d-flex align-items-center mb-4">
          <img src="/assets/img/calendar.svg" alt="">
          <label class="label color-white mx-5">{{ $t("Dates") }}</label>
        </div>
        <date-range-picker v-model="filters.dateRange" class="range-datepicker">
        </date-range-picker>
      </div>

      <!-- <div class="filter__item filter__item-date">
        <label class="label filter__label" for>{{ $t("Dates") }}</label>
        <date-range-picker v-model="filters.dateRange" class="range-datepicker">
        </date-range-picker>
      </div> -->

      <div class="filter__item">
        <div class="d-flex align-items-center mb-4">
          <img src="/assets/img/people.svg" alt="">
          <label class="label color-white mx-5">Travelers</label>
        </div>
        <vue-select
          :options="passengers.options"
          class="input cus-select border-option-dropdown"
          v-model="passengers.selected"
        ></vue-select>
      </div>

      <div class="filter__item">
        <div class="d-flex align-items-center mb-4">
          <img src="/assets/img/icons/umbrella-beach-light.svg" alt="">
          <label class="label color-white mx-5">{{ $t("Type of Deal") }}</label>
        </div>
        <vue-select
          :options="deal.options"
          class="input cus-select border-option-dropdown"
          v-model="deal.selected"
          :placeholder="$t('Select')"
        ></vue-select>
      </div>

      <div class="d-flex justify-content-center align-items-center filter-action-block mt-5">
        <button class="primary-btn">
          {{ $t('Search') }}
          <img 
            src="/assets/img/icons/search-light-white.svg" 
            alt="" 
            class="seach-btn-img"
          >
        </button>
        <button 
          class="primary-btn bg-red6"  
          @click="closeFilterModal()"
        >
          {{ $t("Cancel") }}
        </button>
      </div>
    </section>
</template>

<script>
export default {
  name: "MobileFilterModal",
  props: {
    isFilterModalVisible: {
      default: false
    }
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 90);
    let night = ['1', '89'];
    return {
      filters: {
        from: "TLV",
        dateRange: { 
          startDate: startDate, 
          endDate: endDate, 
          night: night 
        },
        exact_dates: false
      },
      arrival: {
        options: [],
        selected: '',
      },
      passengers: {
        options: [
          {
            code: '',
            label: this.$t('All')
          },
          {
            code: 'AA',
            label: 2 + " " + this.$t('Adults')
          },
          {
            code: 'AAC',
            label: 2 + " " + this.$t('Adults') + " + " + 2 + " " + this.$t('Boy')
          },
          {
            code: 'AACC',
            label: 2 + " " + this.$t('Adults') + " + " + 2 + " " + this.$t('Children')
          },
        ],
        selected: {
          code: '',
          label: this.$t('All')
        }
      },
      deal: {
        options: [
          {
            code: '',
            label: this.$t("All")
          },
          {
            code: 'NOFSHON',
            label: this.$t("NOFSHON")
          },
          {
            code: 'ORGANIZED_TOUR',
            label: this.$t("Organized Tour")
          },
        ],
        selected: {
          code: '',
          label: this.$t("All")
        }
      },
    }
  },
  methods: {
    closeFilterModal() {
      $("html").css("overflow", "auto");
      this.$emit("update:isFilterModalVisible", false);
    }
  }
}
</script>

<style lang="scss">
  .mobile-filter-modal {
    .range-datepicker .reportrange-text {
      padding-top: 0 !important;
      height: 40px;
      padding: 0 15px !important;
    }
  }
</style>

<style lang="scss" scoped>
  .mobile-filter-modal {
    position: fixed;
    top: 60px;
    width: 100vw;
    left: 0;
    right: 0;
    background-color: #002036;
    height: calc(100vh - 60px);
    padding: 15px;
    z-index: 22;
    overflow: auto;
    .filter__item {
      padding: 0;
      height: unset;
      margin-top: 15px;
      &::after {
        display: none;
      }
      .cus-select {
        height: 40px;
        padding-top: 0;
      }
    }
    .filter-action-block {
      .primary-btn {
        padding: 0 20px;
        margin: 0 10px;
        border-radius: 5px;
        .seach-btn-img {
          margin-right: 15px;
        }
      }
    }
  }
  .ltr-type {
     .mobile-filter-modal {
      .filter-action-block {
        .primary-btn {
          .seach-btn-img {
            margin-right: unset;
            margin-left: 15px;
          }
        }
      }
    }
  }
</style>